<script setup>
import { ref, onMounted } from 'vue';
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

const showOptions = ref(true);
const showLoginForm = ref(false);
const showRegisterForm = ref(false);
const isWebview = ref(false);

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const loginForm = useForm({
    email: '',
    password: '',
    remember: false,
});

const registerForm = useForm({
    email: '',
    password: '',
    password_confirmation: '',
});

const submitLogin = () => {
    loginForm.post(route('login'), {
        onFinish: () => loginForm.reset('password'),
    });
};

const submitRegister = () => {
    registerForm.post(route('register'), {
        onFinish: () => registerForm.reset('password', 'password_confirmation'),
    });
};

const continueWithEmail = () => {
    showOptions.value = false;
};

const showLogin = () => {
    showLoginForm.value = true;
    showRegisterForm.value = false;
};

const showRegister = () => {
    showLoginForm.value = false;
    showRegisterForm.value = true;
};

const goBack = () => {
    showOptions.value = true;
    showLoginForm.value = false;
    showRegisterForm.value = false;
};

const redirectToOauth = (provider) => {
    window.location.href = route('auth.socialite.redirect', provider);
};

const checkWebview = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /FBAN|FBAV|Instagram|LinkedInApp/i.test(userAgent);
};

onMounted(() => {
    isWebview.value = checkWebview();
});

</script>

<template>
    <GuestLayout>
        <Head title="Log in or Register" />

        <div v-if="showOptions" class="space-y-4">
            <h2 class="text-2xl font-bold text-gray-800">Welcome!</h2>
            <p class="text-gray-600">Please log in to your account or create a new one.</p>
            
            <button v-if="!isWebview" @click.prevent="redirectToOauth('google')" id="google-login-button" class="w-full py-4 bg-white border rounded-lg flex items-center pl-20 space-x-2 shadow-sm  hover:border-blue-500 font-semibold active:scale-95 ">
                <img src="/img/google_logo.png" alt="Google" class="w-10 h-10" />
                <span>Continue with Google</span>
            </button>
            <p v-if="isWebview" id="webview-warning" class="text-gray-500">If you'd like to login with Google, open this website in a normal web browser, not witin a social app.</p>
            <button @click.prevent="redirectToOauth('linkedin-openid')" class="w-full py-4 bg-white border rounded-lg flex items-center pl-20 space-x-2 shadow-sm hover:border-blue-500 font-semibold active:scale-95">
                <img src="/img/linkedin_logo.png" alt="LinkedIn" class="w-10 h-10" />
                <span>Continue with LinkedIn</span>
            </button>
            <button @click="continueWithEmail" class="w-full py-4 bg-white border rounded-lg flex items-center pl-20 space-x-2 shadow-sm hover:border-blue-500 font-semibold active:scale-95">
                <svg alt="email" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8 m-1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>

                <span>Continue with Email</span>
            </button>
            
            <div class="mt-2 flex justify-center items-center w-full py-3">
                <ul class="list-disc list-inside text-gray-800 space-y-2">
                    <li class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        High quality, realistic headshots
                        
                    </li>
                    <li class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        Easy and fully online 
                    </li>
                    <li class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        100% private and secure
                    </li>
                </ul>

            </div>
        </div>

        <div v-if="!showOptions && !showRegisterForm && !showLoginForm" class="space-y-4">
            <div class="flex space-x-4">
            <button 
                @click="showLogin" 
                class="w-full py-4 bg-white border shadow-sm rounded-lg hover:border-blue-500 active:scale-95"
            >
                I have an account
            </button>
            <button @click="showRegister" class="w-full py-4 bg-white border shadow-sm rounded-lg hover:border-blue-500 active:scale-95">
                I'm new
            </button>
            </div>
            <div class="mt-4 flex justify-start items-center w-full">
                <a @click="goBack" class="text-gray-700 hover:text-gray-700 cursor-pointer flex items-center space-x-2 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                    </svg>
                    <span class="text-sm">Back</span>
                </a>
            </div>
        </div>

        <form v-if="showLoginForm" @submit.prevent="submitLogin" class="space-y-4">
            <div>
                <InputLabel for="email" value="Email" />
                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="loginForm.email"
                    required
                    autofocus
                    autocomplete="username"
                />
                <InputError class="mt-2" :message="loginForm.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password" />
                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="loginForm.password"
                    required
                    autocomplete="current-password"
                />
                <InputError class="mt-2" :message="loginForm.errors.password" />
            </div>

            <div class="mt-4 block">
                <label class="flex items-center">
                    <Checkbox name="remember" v-model:checked="loginForm.remember" />
                    <span class="ms-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div class="mt-4 flex items-center justify-between">
                <a @click="goBack" class="text-gray-700 hover:text-gray-700 cursor-pointer flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                    </svg>
                    <span class="text-sm">Back</span>
                </a>
                <div class="flex items-center">
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Forgot your password?
                    </Link>
                    <PrimaryButton
                        class="ms-4"
                        :class="{ 'opacity-25': loginForm.processing }"
                        :disabled="loginForm.processing"
                    >
                        Log in
                    </PrimaryButton>
                </div>
            </div>
        </form>

        <form v-if="showRegisterForm" @submit.prevent="submitRegister" class="space-y-4">
            <div>
                <InputLabel for="email" value="Email" />
                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="registerForm.email"
                    required
                    autofocus
                    autocomplete="username"
                />
                <InputError class="mt-2" :message="registerForm.errors.email" />
            </div>

            <div class="mt-4 flex space-x-4">
                <div class="w-1/2">
                    <InputLabel for="password" value="Password" />
                    <TextInput
                        id="password"
                        type="password"
                        class="mt-1 block w-full"
                        v-model="registerForm.password"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="mt-2" :message="registerForm.errors.password" />
                </div>

                <div class="w-1/2">
                    <InputLabel for="password_confirmation" value="Confirm Password" />
                    <TextInput
                        id="password_confirmation"
                        type="password"
                        class="mt-1 block w-full"
                        v-model="registerForm.password_confirmation"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="mt-2" :message="registerForm.errors.password_confirmation" />
                </div>
            </div>

            <div class="mt-4 flex items-center justify-between">
                <a @click="goBack" class="text-gray-700 hover:text-gray-700 cursor-pointer flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                    </svg>
                    <span class="text-sm">Back</span>
                </a>
                <PrimaryButton
                    class="ms-4"
                    :class="{ 'opacity-25': registerForm.processing }"
                    :disabled="registerForm.processing"
                >
                    Register
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>